<template>
  <c-flex
    width="100%"
    flex-direction="column"
    gap="16px"
  >
    <c-flex
      justify-content="space-between"
      align-items="center"
    >
      <c-text
        font-size="18px"
        font-weight="500"
        color="neutral.superDarkGray"
      >
        Rekomendasi menu
      </c-text>
      <c-select
        v-model="filterTime"
        variant="filled"
        max-width="150px"
        border-radius="12px"
        color="primary.500"
        icon-size="30"
      >
        <option value="day">
          Hari
        </option>
        <option value="mealtime">
          Jam makan
        </option>
      </c-select>
    </c-flex>
    <c-flex
      v-if="filterTime === 'day'"
      width="100%"
      flex-direction="column"
      gap="16px"
    >
      <ChipFilter
        :options="optionWeeks"
        :selected-filter="selectedWeek"
        justify-content="flex-start"
        :min-width="['10px', '180px']"
        @on-change-filter="onChangeWeek"
      />
      <c-flex
        width="100%"
        align-items="center"
        gap="0px"
      >
        <ChipDays
          :start-at="optionsDays.startAt"
          :end-at="optionsDays.endAt"
          :selected-day="day"
          :invalid-day-validation="optionsDays.validation"
          :dot-badge-days="optionsDays.dotBadgeDay"
          custom-item-min-width="30px"
          @on-change-day="onChangeDay"
        />
        <c-flex
          justify-content="center"
          align-items="center"
          padding-left="16px"
        >
          <MealCalendar 
            :rows="2"
            :columns="1"
            :step="2"
            :attributes="calendarAttributes"
            :min-date="minDate"
            :max-date="maxDate"
            :from-date="fromDate"
            :mask="masks"
            :model-config="modelConfig"
            @update="(el) => onChangeCalendar(el)"
          />
        </c-flex>
      </c-flex>
    </c-flex>
    <c-flex
      v-if="filterTime === 'mealtime' && optionMealtimes?.length > 0"
      width="100%"
      flex-direction="column"
      gap="16px"
    >
      <ChipFilter
        :options="optionMealtimes"
        :selected-filter="selectedMealtime"
        justify-content="flex-start"
        :min-width="['10px', '180px']"
        @on-change-filter="onChangeTime"
      />
    </c-flex>

    <c-box
      v-if="isLoading"
      text-align="center"
      margin-block="36px"
    >
      <c-spinner
        thickness="4px"
        speed="0.65s"
        empty-color="green.200"
        color="primary.400"
        size="xl"
      />
      <c-text
        color="primary.400"
        font-weight="500"
      >
        Memuat data Rekomendasi Menu...
      </c-text>
    </c-box>

    <c-box
      v-else-if="dataParse.errorMessage"
    >
      <NoData
        :text="dataParse.errorMessage"
      />
    </c-box>

    <c-flex
      v-else-if="dataParse.data?.length > 0"
      flex-direction="column"
      width="100%"
      gap="12px"
    >
      <CardInfoMenuRecommendation
        v-for="item_ in dataParse.data"
        :key="item_.id"
        :item="item_"
        @open-menu-recommendation="onOpenMenuRecommendation"
      />
    </c-flex>

    <BaseModal
      :is-open="isOpenModalMenuRecommendation"
      :close-on-overlay-click="false"
      :with-button-close="false"
      :has-footer="false"
      size="800px"
    >
      <template #header>
        <c-flex
          width="100%"
          justify-content="space-between"
          align-items="center"
          padding="16px 20px"
          border-bottom-width="1px"
        >
          <c-text
            :font-size="['14px','24px']"
            font-weight="600"
            color="neutral.black"
          >
            {{ item?.label }} - {{ item?.mealPlanSchedule?.caloriesAmount ?? 0 }} kkal
          </c-text>
          <c-flex
            align-items="center"
            gap="12px"
          >
            <c-text
              :font-size="['12px','16px']"
              color="neutral.superDarkGray"
              :font-weight="['400','500']"
            >
              {{ format24Time(item?.mealPlanSchedule?.startTime, 'HH:mm') }} - {{ format24Time(item?.mealPlanSchedule?.endTime, 'HH:mm') }}
            </c-text>
            <c-button
              variant="ghost"
              :width="['20px','30px']"
              :min-width="['20px','30px']"
              :height="['20px','30px']"
              margin="0"
              padding="0"
              @click="isOpenModalMenuRecommendation = false"
            >
              <c-icon
                name="close"
                :size="['13px','17px']"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </template>
      <template #body>
        <c-box
          width="100%"
          max-height="600px"
          overflow="auto"
        >
          <c-box
            margin-inline="auto"
            max-width="700px"
            padding-inline="20px"
            padding-bottom="16px"
          >
            <c-box
              v-for="(menu, i) in item?.data?.dataMenu"
              :key="i"
            >
              <c-flex
                v-if="menu?.menuType === 'singleEntry'"
                width="100%"
                flex-direction="column"
                border-bottom-width="1px"
                border-bottom-color="#55565"
                cursor="pointer"
                @click="onClickMenuItem(menu, menu?.menuType)"
              >
                <c-flex
                  width="100%"
                  padding-block="8px"
                  align-items="center"
                  gap="16px"
                >
                  <c-image
                    :src="menu?.menus?.photoUrl ? menu?.menus?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                    :width="['60px','100px']"
                    :height="['60px','100px']"
                    object-fit="cover"
                    object-position="center"
                    border-radius="8px"
                  />
                  <c-text
                    :font-size="['12px','16px']"
                    font-weight="500"
                    padding-block="16px"
                  >
                    {{ menu?.menus?.servingSize }} {{ menu?.menus?.servingSizeUnit === 'Lainnya' ? menu?.menus?.servingSizeUnitOther : menu?.menus?.servingSizeUnit }} {{ menu?.menuName }}
                  </c-text>
                </c-flex>
              </c-flex>
              <c-flex
                v-else
                width="100%"
                flex-direction="column"
                border-bottom-width="1px"
                border-bottom-color="#55565"
              >
                <c-flex
                  width="100%"
                  padding-top="16px"
                  padding-bottom="8px"
                  align-items="center"
                  gap="16px"
                >
                  <c-text
                    :font-size="['12px','16px']"
                    font-weight="500"
                  >
                    {{ menu?.menuName }}
                  </c-text>
                </c-flex>

                <c-box>
                  <c-text
                    :font-size="['10px','14px']"
                    color="neutral.superDarkGray"
                    margin-bottom="8px"
                  >
                    Komposisi Makanan
                  </c-text>
                  <c-grid
                    v-if="menu?.menus?.length > 0"
                    template-columns="repeat(5, 1fr)"
                    gap="16px"
                    padding-bottom="8px"
                  >
                    <c-flex
                      v-for="(subMenu, j) in menu?.menus"
                      :key="j"
                      justify-content="flex-start"
                      align-items="center"
                      flex-direction="column"
                      cursor="pointer"
                      @click="onClickMenuItem(subMenu, menu?.menuType)"
                    >
                      <c-image
                        :src="subMenu?.photoUrl ? subMenu?.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'"
                        :width="['60px','100px']"
                        :height="['60px','100px']"
                        object-fit="cover"
                        object-position="center"
                        border-radius="8px"
                        margin-bottom="8px"
                      />
                      <c-text
                        :font-size="['10px','14px']"
                        color="neutral.superDarkGray"
                        text-align="center"
                      >
                        {{ subMenu?.servingSize }} {{ subMenu?.servingSizeUnit === 'Lainnya' ? subMenu?.servingSizeUnitOther : subMenu?.servingSizeUnit }} {{ subMenu?.foodName }}
                      </c-text>
                    </c-flex>
                  </c-grid>
                  <c-box
                    v-else
                  >
                    -
                  </c-box>
                </c-box>
              </c-flex>
            </c-box>
          </c-box>
        </c-box>
      </template>
    </BaseModal>
  </c-flex>
</template>

<script>
import ChipFilter from '@/components/chip-filter.vue'
import ChipDays from '@/components/chip-days.vue'
import MealCalendar from '@/components/meal-calendar2.vue'
import CardInfoMenuRecommendation from '@/components/meal-plan/_widgets/card-info-menu-recommendation.vue'
import dayjs from 'dayjs'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import NoData from '@/components/no-data.vue'
import { CBox, CFlex } from '@chakra-ui/vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { format24Time } from '@/utils/format-24-time'
import { mapMutations } from 'vuex'
import { reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus } from '@/requests/dietela-api/nutritionist/other'
import { mealTileIdToLabel } from '@/constants/meal-time'
let day = dayjs

export default {
  name: 'PreviewMenuRecommendation',
  components: {
    BaseModal,
    CFlex, CBox,
    ChipFilter,
    ChipDays,
    MealCalendar,
    CardInfoMenuRecommendation,
    NoData,
  },
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
    calendars: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenModalMenuRecommendation: false,
      item: {},
      weekDays: [],
      selectedWeek: {},
      selectedMealtime: {},
      masks: {
        input: 'DD-MM-YYYY',
        data: 'YYYY-MM-DD',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
        timeAdjust: '00:00:00',
      },
    }
  },
  computed: {
    optionMealtimes() {
      return Object.entries(mealTileIdToLabel)
        .filter(([id]) => !this.data?.mealPlanSchedules?.find((item) => item?.mealTime === id)?.isSkipped)
        .map(([id, label]) => {
          return {
            id,
            label,
          }
        }) || []
    },
    day() {
      return this.$route.query.day ?? 1
    },
    month() {
      return this.$route.params.month ?? 1
    },
    mealtime() {
      return this.$route.query.mealtime ?? this.optionMealtimes[0].id
    },
    filterTime: {
      get() {
        return this.$route.query.time ?? 'day'
      },
      set(val) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            time: val,
          },
          params: {
            ...this.$route.params,
            savePosition: true,
          },
        }).catch(() => {})
      },
    },
    optionWeeks() {
      return this.weekDays.map((it) => {
        return {
          id: it?.week,
          label: `Minggu ${it?.week}`,
          days: it?.days,
          disabled: false, 
        }
      })
    },
    optionsDays() {
      if (this.selectedWeek && this.selectedWeek?.days?.length > 0) {
        const { smallestDay, biggestDay } = this.findMinMaxDay(this.selectedWeek?.days)
        const validation = this.selectedWeek?.days?.filter((it) => it?.validation === -1 || it?.validation === 0).map((obj) => obj?.day)
        const dotBadgeDay = this.selectedWeek?.days?.filter((it) => it?.isBulkCreate === 1).map((obj) => obj.day)
        return {
          startAt: smallestDay,
          endAt: biggestDay,
          validation: validation,
          dotBadgeDay,
        }
      } else {
        return {
          startAt: 0,
          endAt: 0,
          validation: [],
          dotBadgeDay: [],
        }
      }
    },
    selectedDate() {
      if (this.calendars && this.calendars.length > 0) {
        return this.calendars.find((it) => it?.days == this.day && it?.month == this.month)
      }
      return null
    },
    today() {
      return day().format('YYYY-MM-DD')
    },
    fromDate() {
      return day(this.today).toDate()
      // if (this.selectedDate) {
      //   return dayjs(this.selectedDate?.date).toDate()
      // }
      // return null
    },
    minDate() {
      if (this.calendars && this.calendars.length > 0) {
        return day(this.calendars[0]?.date).format('YYYY-MM-DD')
      }
      return ''
    },
    maxDate() {
      if (this.calendars && this.calendars.length > 0) {
        return day(this.calendars[this.calendars.length -1]?.date).format('YYYY-MM-DD')
      }
      return ''
    },
    calendarAttributes() {
      if (this.calendars && this.calendars.length > 0) {
        const selectedDate = this.calendars.find((it) => it?.date == this.today)
        let attributes
        attributes = this.calendars.map((it) => { 
          return {
            key: it?.id,
            highlight: {
              style: {
                backgroundColor: selectedDate?.id === it?.id ? '#008C81' : '#C7F9E3',
                borderRadius: selectedDate?.id === it?.id ? '100%' : '8px',
              },
              fillMode: selectedDate?.id === it?.id ? 'solid' : 'light',
            },
            dates: day(it?.date).toDate(),
            popover: {
              label: `Bulan ke- ${it?.month}, Hari ke-${it?.days}`,
              visibility: 'hover',
              hideIndicator: true,
            },
          }
        })

        if (!selectedDate) {
          attributes = [
            ...attributes,
            {
              key: 'today',
              highlight: {
                style: {
                  backgroundColor: '#008C81',
                  borderRadius: '100%',
                },
                fillMode: 'solid',
              },
              dates: day(this.today).toDate(),
            },
          ]
        }
        return attributes
      }
      return []
    },
    dataParse() {
      if (this.filterTime === 'day' && this.data?.id && Object.keys(this.data).length > 0) {
        const arr = [
          {
            id: 'breakfast',
            label: 'Sarapan',
            data: this.data?.breakfast ?? null,
          },
          {
            id: 'morningSnack',
            label: 'Snack Pagi',
            data: this.data?.morningSnack ?? null,
          },
          {
            id: 'lunch',
            label: 'Makan Siang',
            data: this.data?.lunch ?? null,
          },
          {
            id: 'afternoonSnack',
            label: 'Snack Siang/Sore',
            data: this.data?.afternoonSnack ?? null,
          },
          {
            id: 'dinner',
            label: 'Makan Malam',
            data: this.data?.dinner ?? null,
          },
          {
            id: 'nightSnack',
            label: 'Snack Malam',
            data: this.data?.nightSnack ?? null,
          },
        ]

        const arrMap = arr
          ?.filter((v) => !v?.data?.isSkipped)
          .map((item) => {
            if (item.data) {
              const findMealPlanScheduleByMealTime = this.data?.mealPlanSchedules?.find((x) => x?.mealTime === item?.id)
              return {
                ...item,
                mealPlanSchedule: findMealPlanScheduleByMealTime,
              }
            }
            return item
          })
        return {
          data: arrMap,
          errorMessage: arrMap.length <= 0 ? 'Rekomendasi menuku belum terisi' : '',
        }
      } else if (this.filterTime === 'mealtime' && this.data?.menuRecommendation?.length > 0) {
        const isSkipped = this.data?.menuRecommendation?.[0]?.[this.mealtime]?.isSkipped
        if (isSkipped) {
          return {
            data: [],
            errorMessage: 'Jam makan di skip',
          }
        }
        const hasData = this.data?.menuRecommendation?.[0]?.[this.mealtime]
        if (!hasData) {
          return {
            data: [],
            errorMessage: 'Tidak ada data tersedia',
          }
        }
        const arrMap = this.data?.menuRecommendation
          .map((item) => {
            return {
              id: item.day,
              label: mealTileIdToLabel[this.mealtime],
              day: 'Hari ' + item.day,
              mealPlanSchedule: item[this.mealtime],
              data: item[this.mealtime],
            }
          })
        return {
          data: arrMap,
          errorMessage: arrMap.length <= 0 ? 'Rekomendasi menuku belum terisi' : '',
        }
      }
      return {
        data: [],
        errorMessage: 'Rekomendasi menuku belum terisi',
      }
    },
  },
  watch: {
    day: {
      handler() {
        this.setSelectedWeek()
      },
    },
    month: {
      async handler() {
        await this.init()
        this.setSelectedWeek()
      },
    },
    data: {
      handler(val) {
        if (val?.isBulkCreate) {
          this.updateBulkStatus()
        }
      },
    },
    mealtime: {
      handler() {
        this.setSelectedMealtime()
      },
    },
  },
  async mounted() {
    await this.init()
    this.setSelectedWeek()
    this.setSelectedMealtime()
  },
  methods: {
    ...mapMutations({
      setMenuRecommendationWeeksDays: 'mealPlan/setMenuRecommendationWeeksDays',
    }),
    format24Time,
    async init() {
      const res = await reqNutriMealPlan.getMenuRecommendationWeekDays(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.setMenuRecommendationWeeksDays(res?.data?.data)
      this.weekDays = res?.data?.data?.weeks
    },
    onOpenMenuRecommendation(item) {
      this.isOpenModalMenuRecommendation = true
      this.item = item
    },
    setSelectedWeek() {
      const week = this.findObjectWithDay(this.day, this.optionWeeks)
      if (week) {
        this.selectedWeek = week
      }
    },
    setSelectedMealtime() {
      const mealtime = this.optionMealtimes?.find((v) => v.id === this.mealtime)
      if (mealtime) {
        this.selectedMealtime = mealtime
      }
    },
    onChangeWeek(week) {
      this.selectedWeek = week
    },
    onChangeDay(day) {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          day,
        },
        params: {
          ...this.$route.params,
          savePosition: true,
        },
      }).catch(() => {})
    },
    onChangeTime(val) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          mealtime: val?.id,
        },
        params: {
          ...this.$route.params,
          savePosition: true,
        },
      }).catch(() => {})
    },
    onChangeCalendar(date) {
      const find = this.calendars.find((it) => it?.id === date?.attributes[0]?.key)
      this.setSelectedWeek()
      this.$router.push({
        ...this.$route,
        params: {
          ...this.$route.params,
          month: find?.month,
          savePosition: true,
        },
        query: {
          day: find?.days,
        },
      }).catch(() => {})
    },
    findMinMaxDay(array) {
      let smallestDay = Infinity
      let biggestDay = -Infinity

      for (const item of array) {
        const currentDay = item?.day

        smallestDay = Math.min(smallestDay, currentDay)
        biggestDay = Math.max(biggestDay, currentDay)
      }

      return { smallestDay, biggestDay }
    },
    findObjectWithDay(dayToFind, arr) {
      for (const week of arr) {
        const days = week?.days

        for (const dayObject of days) {
          if (dayObject?.day == dayToFind) {
            return week
          }
        }
      }
      
      return null
    },
    onClickMenuItem(menu, type) {
      if (menu?.menus?.mealGlossariesId || menu?.mealGlossariesId) {
        return this.$router.push({
          name: 'client.glossaries-detail',
          params: {
            glossaryId: type === 'singleEntry' ? menu?.menus?.mealGlossariesId : menu?.mealGlossariesId,
            savePosition: true,
          },
        })
      }
      this.$router.push({
        name: 'client.glossaries-detail',
        params: {
          glossaryId: 'other',
          savePosition: true,
        },
        query: {
          name: type === 'singleEntry' ? menu?.menuName : menu?.foodName,
          servingSize: type === 'singleEntry' ? menu?.menus?.servingSize : menu?.servingSize,
          servingSizeUnit: type === 'singleEntry' ? (menu?.menus?.servingSizeUnitOther ? menu?.menus?.servingSizeUnitOther : menu?.menus?.servingSizeUnit) : (menu?.servingSizeUnitOther ? menu?.servingSizeUnitOther : menu?.servingSizeUnit),
        },
      })
    },
    async updateBulkStatus() {
      const menuRecommendationId = this.data?.id
      await reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus(this.$store.getters.axios, {
        id: menuRecommendationId,
      })
      await this.init()
      this.setSelectedWeek()
    },
  },
}
</script>

<style>

</style>